<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                New Bill of Materials
            </h2>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <div>
                <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="reservesFiltered"
                    class="elevation-0 mt-5"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :style="`height: ${height}px`"
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            style="background-color: #eeeeee"
                        >
                            <v-col cols="12">
                                <h2 class="mx-4 my-n3">RESERVED STOCK</h2>
                            </v-col>
                        </v-row>
                    </template>
                    <!--HEADERS-->
                    <template v-slot:[`header.name`]="{ header }">
                        <v-text-field
                            class="py-2 mt-2"
                            :label="header.text"
                            v-model="itemToFilter"
                            dense
                            prepend-icon="mdi-filter"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.name`]="{ item }">
                        <p class="my-0">
                            {{ `${item.material} ${item.short_material_text}` }}
                        </p>
                    </template>
                    <template v-slot:[`item.quantity`]="{ item }">
                        <div v-if="loading">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                size="24"
                            ></v-progress-circular>
                        </div>
                        <div v-else>
                            <p class="my-0">{{ item.free_use }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                        <v-form ref="form" v-model="valid">
                            <v-text-field
                                type="number"
                                v-model="item.bomQuantity"
                                :rules="[
                                    () =>
                                        rules.maxValue(item.bomQuantity, item),
                                ]"
                            />
                        </v-form>
                    </template>
                    <template v-slot:[`item.notes`]="{ item }">
                        <v-text-field v-model="item.notes" />
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mt-n2 mb-3 mx-2"
                :disabled="!valid"
                @click="saveBOM"
            >
                {{
                    createForm
                        ? 'ADD NEW BILL OF MATERIAL'
                        : 'EDIT BILL OF MATERIAL'
                }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import { storage } from '@/services/firebase'
import moment from 'moment'

export default {
    name: 'BOMForm',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        bom: {
            type: Object,
            default: () => {},
        },
        originalBom: {
            type: Object,
            default: () => {},
        },
        createForm: {
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        aux_reserves: [],
        boms: [],
        reserves: [],
        loading: false,
        itemToFilter: undefined,
        headers: [
            {
                text: 'ITEM',
                value: 'name',
                sortable: false,
                align: 'left',
            },
            {
                text: 'AVAILABLE',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'STORE',
                value: 'storage',
                sortable: false,
                align: 'left',
            },
            {
                text: 'QTY',
                value: 'qty',
                sortable: false,
                align: 'left',
                width: '100',
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                align: 'left',
            },
        ],
        height: 0,
        rules: {
            maxValue: (v, item) => {
                const maxValue = Number(item.free_use)
                return (
                    (v <= maxValue && maxValue > 0) || `The value is not valid.`
                )
            },
        },
        valid: false,
        companyId: JSON.parse(localStorage.getItem('company')),
    }),
    computed: {
        reservesFiltered() {
            let conditions = []
            if (this.itemToFilter) {
                conditions.push(this.filterItem)
            }
            return this.reserves.filter(reserve => {
                return conditions.every(condition => {
                    return condition(reserve)
                })
            })
        },
    },
    async mounted() {
        try {
            this.onresize()
            this.loading = true
            await this.getReserves()
            await this.calculateStock()
            if (this.bom.id) {
                this.bom.items.forEach(request => {
                    const reserveIndex = this.reserves.findIndex(
                        reserve =>
                            request.description
                                .toLowerCase()
                                .includes(
                                    `${reserve.material}`.toLowerCase()
                                ) &&
                            request.store == reserve.storage &&
                            request.order ==
                                (reserve.order || reserve.orderNumber)
                    )
                    if (reserveIndex > -1) {
                        this.reserves.splice(reserveIndex, 1, {
                            ...this.reserves[reserveIndex],
                            bomQuantity: request.quantity,
                            notes: request.notes,
                            id: request.id,
                        })
                    }
                })
            }
            this.reserves.forEach(reserve => {
                reserve.prevBomQuantity = reserve.bomQuantity
                    ? reserve.bomQuantity
                    : 0
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getReserves() {
            try {
                this.loading = true
                const reserves = await API.getReservedStock(
                    this.workOrder.projectId
                )
                for (const reserve of reserves) {
                    const aux_reserve_index = this.aux_reserves.findIndex(
                        r =>
                            r.material == reserve.material &&
                            r.storage == reserve.storage
                    )
                    if (aux_reserve_index > -1) {
                        const newFreeUse =
                            Number(
                                this.aux_reserves[aux_reserve_index].free_use ||
                                    0
                            ) + Number(reserve.free_use || 0)
                        this.aux_reserves.splice(aux_reserve_index, 1, {
                            ...this.aux_reserves[aux_reserve_index],
                            free_use: newFreeUse,
                        })
                    } else {
                        this.aux_reserves.push(reserve)
                    }
                }
                this.reserves = _.cloneDeep(this.aux_reserves)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async calculateStock() {
            try {
                this.loading = true

                let lastStockUpdate = null
                const storageRef = storage()
                    .ref(`${this.companyId}/stock`)
                    .child('stock.json')
                await storageRef.getMetadata().then(metadata => {
                    lastStockUpdate = metadata.updated
                })

                const queryDate = moment(
                    lastStockUpdate.substring(0, 10)
                ).format('YYYY-MM-DD')
                //const stockDate = new Date(lastStockUpdate)
                const boms = await API.getBOMsChangelogForStock({
                    projectId: this.workOrder.projectId,
                    status: ['inProgress', 'approved'],
                    startDate: queryDate,
                })

                let activeBoms = boms.filter(bom => {
                    return bom.id != this.bom.id
                })

                const activeItems = activeBoms.flatMap(bom => bom.items)
                activeItems.forEach(
                    item => (item.material = item.description.split(' ')[0])
                )

                this.reserves.forEach(reserve => {
                    const usedReserves = activeItems.filter(
                        i =>
                            i.material == reserve.material &&
                            i.store == reserve.storage &&
                            i.order == (reserve.order || reserve.orderNumber)
                    )
                    const usedQuantity = usedReserves.reduce(
                        (total, item) => total + item.quantity,
                        0
                    )
                    reserve.free_use = reserve.free_use - usedQuantity
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveBOM() {
            if (this.createForm) {
                await this.addBOM()
            } else {
                await this.editBOM()
            }
        },
        async addBOM() {
            try {
                this.loading = true
                const requests = this.reserves.filter(reserve => {
                    if (!reserve.bomQuantity) {
                        return false
                    }
                    reserve.bomQuantity = Number(reserve.bomQuantity)
                    return reserve.bomQuantity && reserve.bomQuantity > 0
                })
                const boms = await API.createBOM({
                    workOrderId: this.workOrder.id,
                    notificationId: 'hP0RpVXam3bUS0XeR9Go',
                    requests,
                })
                this.$emit('reload', boms)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async editBOM() {
            try {
                this.loading = true
                const requests = this.reserves.filter(reserve => {
                    delete reserve.prevBomQuantity
                    if (
                        reserve.bomQuantity != undefined &&
                        reserve.bomQuantity != ''
                    ) {
                        reserve.bomQuantity = Number.parseInt(
                            reserve.bomQuantity
                        )
                    }
                    if (
                        Number.isInteger(reserve.bomQuantity) ||
                        reserve.notes
                    ) {
                        if (reserve.id) {
                            const bomItem = this.bom.items.find(
                                item => item.id == reserve.id
                            )
                            if (
                                bomItem.quantity == reserve.bomQuantity &&
                                bomItem.notes == reserve.notes
                            ) {
                                return false
                            }
                            return true
                        }
                        return true
                    }
                })
                await API.updateBOM({
                    workOrderId: this.workOrder.id,
                    bomId: this.bom.id,
                    notificationId: 'FAbZ7a7PCL3KccuFMrc6',
                    requests,
                })
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        filterItem(reserve) {
            const material =
                typeof reserve.material == 'number'
                    ? reserve.material.toString()
                    : reserve.material
            return (
                material.toLowerCase().includes(this.itemToFilter) ||
                reserve.short_material_text
                    .toLowerCase()
                    .includes(this.itemToFilter)
            )
        },
        onresize() {
            this.height = 446
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
